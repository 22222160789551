


window.addEventListener('load', function(){
    var nav = document.getElementById('js-navArea');
    console.log(nav);
    var toggle_btn = document.getElementsByClassName('toggle_btn');
    console.log(toggle_btn);
    var mask = document.getElementById('mask');
    var open = 'open';
    if(toggle_btn.length > 0){
        toggle_btn[0].addEventListener('click', function (event) {
            console.log(nav);
            console.log(nav.classList);
            if(nav.classList.contains('open')){
                nav.classList.remove('open');
            }else{
                nav.classList.add('open');
            }
        });
    }
    mask.addEventListener('click', function (event) {
        nav.classList.remove('open');
    });
});